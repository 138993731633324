import { useEffect, useMemo, useState } from "react"

import { AxiosResponse } from "axios"
import { DateTime } from "luxon"
import { useTranslation } from "react-i18next"

import { HTTPStatus } from "$/constants"
import useBoolean from "$/hooks/use-boolean"
import { isNullOrUndefined } from "$/utils/gates"
import { getErrorMessages } from "$/utils/get-error-messages"

import useCoachTimeWidget from "@/components/onboarding/api/use-coach-time-widget"
import { CronofyNotificationType, DATE_FORMAT, ERROR_TEXT, SessionScheduleStatus } from "@/constants"
import { ThreeWayManagerApi, ThreeWayManagerFormValues } from "@/entities/three-way"
import Coach from "@/models/coach"
import { Session } from "@/models/session"
import Avatar from "@/shared/avatar/Avatar"
import Modal from "@/shared/modal"
import { formatDateSessions } from "@/utils/date"

import CoachTimeScheduleContainer from "./CoachTimeScheduleContainer"
import { BookedTime } from "./types"

import "./styles.scss"

type Props = {
    show: boolean
    coach: Coach | null
    title?: string
    sessionTime?: string
    session: {
        duration: number
        session_type?: string
        name?: string
        id?: number
    }
    allUpcomingSessions?: Session[]
    showManagerInfoBlock?: boolean
    scheduledSession?: any
    sessionId: number
    onClose: (bookedStatus: SessionScheduleStatus) => void
    timePickRequest: (
        notification,
        threeWayManager?: ThreeWayManagerFormValues | ThreeWayManagerApi
    ) => Promise<AxiosResponse<any, any>>
    closeOnEscapePress?: boolean
}

const CoachTimeScheduleModal = ({
    show,
    coach,
    title = "",
    sessionTime,
    session,
    allUpcomingSessions = [],
    showManagerInfoBlock = false,
    scheduledSession,
    sessionId,
    timePickRequest,
    onClose,
    closeOnEscapePress = false
}: Props) => {
    const { t } = useTranslation()
    const [errorsMsg, setErrorsMsg] = useState(null)
    const { value: showBackBtn, setValue: setShowBackBtn } = useBoolean(false)
    const { value: isBusyWidget, setValue: setIsBusyWidget } = useBoolean(false)
    const {
        data: cronofyOptions,
        isLoading,
        isError: isWidgetError,
        error: widgetError
    } = useCoachTimeWidget(coach?.id || coach?.id, scheduledSession?.session_id || "")
    const [bookedTime, setBookedTime] = useState<BookedTime>(null)

    useEffect(() => {
        if (isWidgetError && show) {
            setErrorsMsg(getErrorMessages(widgetError))
            setShowBackBtn(false)
        }
    }, [isWidgetError, show])

    const onTimePick = async ({ notification }) => {
        if (notification.type === CronofyNotificationType.SLOT_SELECTED) {
            setIsBusyWidget(true)
            try {
                await timePickRequest(notification)
                setBookedTime({
                    time: notification.slot.start,
                    tz: notification.tzid
                })
            } catch (error) {
                if (error?.response?.status === HTTPStatus.INTERNAL_SERVER_ERROR) {
                    setErrorsMsg(ERROR_TEXT)
                    setShowBackBtn(false)
                } else {
                    setErrorsMsg(getErrorMessages(error))
                    setShowBackBtn(true)
                }
            } finally {
                setIsBusyWidget(false)
            }
        }
    }

    const onClickBackBtn = () => {
        setErrorsMsg(null)
        setShowBackBtn(false)
    }

    const onCloseCoachSelectModal = () => {
        if (bookedTime) {
            onClose(SessionScheduleStatus.SCHEDULED)
            setBookedTime(null)
        } else {
            onClose(SessionScheduleStatus.NOT_SCHEDULED)
        }

        if (errorsMsg) {
            setErrorsMsg(null)
        }
    }

    const options = useMemo(() => {
        const hasSession = !isNullOrUndefined(session)
        const hasCronofyOptions = !isNullOrUndefined(cronofyOptions)

        if (hasSession && hasCronofyOptions) {
            return {
                ...cronofyOptions,
                availability_query: {
                    ...cronofyOptions?.availability_query,
                    required_duration: {
                        minutes: session?.duration
                    }
                },
                tzid: Intl.DateTimeFormat().resolvedOptions().timeZone
            }
        }

        return null
    }, [cronofyOptions, session])

    const scheduledTime = sessionTime ?? bookedTime?.time ?? null

    return (
        <Modal
            onClose={onCloseCoachSelectModal}
            show={show}
            modalBodyClass="coach-time-select-modal"
            headerContent={
                <div className="d-flex align-items-center mx-5">
                    <Avatar url={coach?.photo} className="mr-20" width={40} height={40} />
                    <div>
                        <p className="m-0 font-weight-800">{title || session?.name || session?.session_type}</p>
                        <span className="color-gray fs-14">
                            {session?.duration} {session?.duration ? t("min") : null}
                        </span>
                    </div>
                    {scheduledTime ? (
                        <div className="ml-md-20 ml-4">
                            <p className="m-0">{formatDateSessions(scheduledTime, false)}</p>
                            <span className="color-gray">
                                {DateTime.fromISO(scheduledTime).toFormat(DATE_FORMAT.TIMEZONE_ABBREVIATED)}
                            </span>
                        </div>
                    ) : null}
                </div>
            }
            disableBodyScroll
            closeOnEscapePress={closeOnEscapePress}
            enableOutSideClick={false}
        >
            <CoachTimeScheduleContainer
                data={options}
                error={Array.isArray(errorsMsg) ? errorsMsg?.join(" ") : errorsMsg}
                onTimePick={onTimePick}
                bookedTime={bookedTime}
                showBackBtn={showBackBtn}
                isLoading={isLoading || isBusyWidget}
                coach={coach}
                showManagerInfoBlock={showManagerInfoBlock}
                session={session}
                allUpcomingSessions={allUpcomingSessions}
                sessionId={sessionId}
                onCloseModal={onCloseCoachSelectModal}
                onClickBack={onClickBackBtn}
            />
        </Modal>
    )
}

export default CoachTimeScheduleModal
