import { FC, startTransition, useCallback, useEffect, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { useQueryClient } from "@tanstack/react-query"
import { AxiosError, AxiosResponse } from "axios"
import classNames from "classnames"
import { useForm } from "react-hook-form"
import { type NavigateFunction, useLocation, useNavigate } from "react-router-dom"

import { HTTPStatus } from "$/constants"
import useIsMobileLandscape from "$/hooks/use-is-mobile-landscape"
import { getErrorMessages } from "$/utils/get-error-messages"
import { getIndexPage } from "$/utils/get-index-page"

import { type TLoginMutation, useLoginMutation } from "@/api/mutations/use-login-mutation"
import useSsoEmail from "@/api/mutations/use-sso-email"
import "@/assets/auth.scss"
import { CoachUrls, PRODUCT_TYPE_WITH_ENROLLMENTS, ParticipantUrls, ServerStateKeys, userRoles } from "@/constants"
import { type IAuthContext, useAuthContext } from "@/context"
import WarningToast from "@/elements/warning-toast/WarningToast"
import { type IUseTranslation, useTranslation } from "@/hooks"
import { SsoOrganization } from "@/models/api/sso"
import User, { UserApi } from "@/models/user"
import { ELocalStorageServiceItemKey, StorageService } from "@/services"
import { CopywriteText } from "@/shared/copywrite/CopywriteText"
import TextInput from "@/shared/input/TextInput"
import { matchingRouteByRole } from "@/utils/common"
import useNavigationOnboarding from "@/utils/hooks/use-navigation-onboarding"
import { loginSchema } from "@/validation"

import { WarningMessage } from "../onboarding/components/WarningMessage"

import EmailModal from "./EmailModal"
import SsoModal from "./SsoModal"
import VerificationModal from "./VerificationModal"

type LocationState = { showMessage: boolean; from: string }

const showDashboard = (user: User) => {
    return PRODUCT_TYPE_WITH_ENROLLMENTS.includes(user.productType)
}

const storageService: StorageService = new StorageService()

const Login: FC = () => {
    const { t }: IUseTranslation = useTranslation()
    const {
        register,
        handleSubmit,
        getValues,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(loginSchema)
    })
    const queryClient = useQueryClient()
    const location = useLocation()
    const { getNextStepUrl } = useNavigationOnboarding()
    const { setUser, user }: IAuthContext = useAuthContext()
    const [errorsMsg, setErrorsMsg] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [showSsoModal, setShowSsoModal] = useState(false)
    const [ssoData, setSsoData] = useState<SsoOrganization>(null)
    const checkSSOEmail = useSsoEmail()
    const [verifyModal, setVerifyModal] = useState({ show: false, email: null, tfa_key: null })
    const isLandscape = useIsMobileLandscape()

    const locationState = location.state as LocationState
    const showToastMessage = locationState?.showMessage

    const navigate: NavigateFunction = useNavigate()

    const navigateUser = useCallback(
        (user: User) => {
            const isCoach = userRoles.coach.includes(user.role)

            if (!isCoach && user?.hasNoCompletedSurveys && user?.onboardingFinished) {
                navigate(ParticipantUrls.SURVEYS)
                return
            }

            if (!user.cohortExists && !isCoach) {
                navigate(ParticipantUrls.WAITING_LIST)
                return
            }

            const hasPermissionOnRoute = location?.state?.from
                ? matchingRouteByRole(user.role, location?.state?.from)
                : false
            if (hasPermissionOnRoute) {
                navigate(location?.state?.from)
                return
            }

            if (isCoach) {
                if (user?.hasSubUsers) {
                    navigate(`${CoachUrls.WELCOME}${location?.state?.search ?? String()}`)
                } else {
                    navigate(CoachUrls.SESSIONS_LIST)
                }
            } else {
                if (user.onboardingFinished) {
                    const page = getIndexPage(user, showDashboard(user))
                    navigate(page)
                    return
                }
                getNextStepUrl().then(step => navigate(step.url))
            }
        },
        [getNextStepUrl, location?.state, navigate]
    )

    const onLoginActions = useCallback(
        (data: UserApi) => {
            const currentUser: User = new User(data) // TODO: TD - refactor with user adapter
            setUser(currentUser)
            navigateUser(currentUser)
        },
        [navigateUser, setUser]
    )

    const loginMutation: TLoginMutation = useLoginMutation()

    const handleOnLogin = useCallback(
        async values => {
            await loginMutation.mutateAsync(values, {
                onSuccess: ({ data }: AxiosResponse) => {
                    queryClient.invalidateQueries([ServerStateKeys.ProductType])
                    storageService.removeItem(ELocalStorageServiceItemKey.MainUserLoggedOut)

                    if ("tfa_key" in data) {
                        setVerifyModal({
                            show: true,
                            email: values.email,
                            tfa_key: data.tfa_key
                        })
                    } else {
                        onLoginActions(data)
                    }
                },
                onError: (error: AxiosError) => {
                    if (
                        error?.response?.status === HTTPStatus.BAD_REQUEST ||
                        error?.response?.status === HTTPStatus.TOO_MANY_REQUESTS
                    ) {
                        setErrorsMsg(getErrorMessages(error))
                    }
                    console.log({ error })
                }
            })
        },
        [loginMutation, onLoginActions, queryClient]
    )

    useEffect(() => {
        if (!user) return

        startTransition((): void => navigateUser(user))
    }, [user, navigateUser])

    const checkSsoUser = async () => {
        const email: string = getValues("email")
        const { data } = await checkSSOEmail.mutateAsync(email)

        if (data) {
            setSsoData(data)
            setShowSsoModal(true)
        }
    }

    return (
        <>
            <section
                className={classNames("registration-section welcome-section reset-section login vh-100-xs", {
                    "message-above": showToastMessage,
                    "is-landscape": isLandscape
                })}
            >
                <h3 className="registration-heading__bold mb-reg__heading">{t("Welcome to Wiser!")}</h3>
                <form className="registration-form mb-auto" onSubmit={handleSubmit(handleOnLogin)}>
                    <WarningMessage message={errorsMsg} align="start" />
                    <TextInput
                        name="email"
                        type="text"
                        groupClassName="mb-2"
                        placeholder={t("Email Address")}
                        register={register}
                        onBlur={checkSsoUser}
                        errors={errors}
                        isError={errorsMsg}
                    />
                    <TextInput
                        name="password"
                        type="password"
                        placeholder={t("Password")}
                        groupClassName="mb-2"
                        register={register}
                        errors={errors}
                        isError={errorsMsg}
                    />
                    <div className="forgot-password">
                        <span>{t("Forgot your password?")}</span>
                        <button type="button" className="reset-modal font-extrabold" onClick={() => setOpenModal(true)}>
                            {t("Reset")}
                        </button>
                    </div>
                    <div className="form-group mt-input-reset mt-input-signin mg-auto d-block text-center">
                        <button
                            className="registration-input__register registration-input__mobile registration-input__reset registration-input__register btn-flip"
                            data-back={t("Sign in")}
                            data-front={t("Sign in")}
                            aria-label={t("Sign in")}
                            type="submit"
                        />
                    </div>
                </form>
            </section>

            <CopywriteText
                className={classNames("text-center copywrite-text-bottom", { "is-landscape": isLandscape })}
            />
            <EmailModal active={openModal} setActive={setOpenModal} />
            {showToastMessage && (
                <WarningToast top="70px" left="50%">
                    <b>{t("Oops!")}</b>{" "}
                    {t("Looks like you’ve already registered with that email. Please sign in below")}
                </WarningToast>
            )}
            <VerificationModal
                email={verifyModal.email}
                show={verifyModal.show}
                tfa_key={verifyModal.tfa_key}
                onClose={() => {}}
                onSubmit={onLoginActions}
            />
            <SsoModal active={showSsoModal} ssoData={ssoData} onClose={() => setShowSsoModal(false)} />
        </>
    )
}

export default Login
