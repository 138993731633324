import { FC, ReactNode, memo } from "react"

import classNames from "classnames"

import useMediaQuery from "$/hooks/use-media-query"

import type { IParticipantModel } from "@/adapters"
import { MAX_WIDTH_MOBILE_MEDIA } from "@/constants"
import { type IAuthContext, useAuthContext, useStoreContext } from "@/context"
import { type TUseLogoNavigation, useLogoNavigation } from "@/hooks"
import ProductTypeLogo from "@/shared/ProductTypeLogo"

import UserHeaderDropdown from "./UserHeaderDropdown"

interface IProps {
    header?: JSX.Element | ReactNode | null
    withBurgerMenu?: boolean
    showLogo?: boolean
    fullWidth?: boolean
}

const ParticipantLayoutHeader: FC<IProps> = ({ header, withBurgerMenu = true, fullWidth = false, showLogo = true }) => {
    const { logout, user }: IAuthContext = useAuthContext()
    const { isOpenBurgerMenu, toggleBurgerMenu } = useStoreContext()
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)

    const handleLogout = e => {
        e.preventDefault()

        logout({ keepLocalStorageAuthTokens: (user as IParticipantModel)?.isFakeParticipant })
    }

    const { handleLogoNavigation }: TUseLogoNavigation = useLogoNavigation()

    return (
        <div
            className={classNames("participant-layout-header", {
                "gray-bg": isOpenBurgerMenu,
                "w-100": fullWidth,
                "justify-content-between": fullWidth,
                "show-spacing": !!header,
                "fake-mode": (user as IParticipantModel)?.isFakeParticipant
            })}
        >
            <div
                className={classNames("sidebar-menu__logo--mobile", {
                    "d-none": isOpenBurgerMenu || !isMobile || !showLogo,
                    "d-inline": fullWidth
                })}
            >
                <ProductTypeLogo handleLogoClick={handleLogoNavigation} />
            </div>
            <div className={classNames("participant-layout-header-center", { "d-none": isOpenBurgerMenu })}>
                {header}
            </div>

            <UserHeaderDropdown handleLogout={handleLogout} user={user} showOnMobile={!isMobile} />

            {withBurgerMenu && isMobile && (
                <button
                    className={classNames("menu-hamburger ml-auto", { open: isOpenBurgerMenu })}
                    onClick={toggleBurgerMenu}
                >
                    <span />
                    <span />
                    <span />
                </button>
            )}
        </div>
    )
}

export default memo(ParticipantLayoutHeader)
