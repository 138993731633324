import { type KeyboardEvent, type MouseEvent, useCallback } from "react"

import { type TEmptyCallback } from "@/shared/types/functions"

const SUPPORTED_KEYDOWN_KEYS: string[] = [String("Enter"), String(" ")]

type TUseAccessibleItemActions = { handleKeyDown(event: KeyboardEvent): void; handleClick(event: MouseEvent): void }

function useAccessibleItemActions(this: { cb?: TEmptyCallback }): ReturnType<() => TUseAccessibleItemActions> {
    const handleKeyDown: (event: KeyboardEvent) => void = useCallback((event: KeyboardEvent): void => {
        if (SUPPORTED_KEYDOWN_KEYS.includes(event.key)) {
            if (event.key === String(" ")) event.preventDefault()
            this?.cb()
        }
    }, [])
    const handleClick: (event: MouseEvent) => void = useCallback((_event: MouseEvent) => this?.cb?.(), [])

    return { handleKeyDown, handleClick }
}

type TUseEscapePress = { handleEscapePress(event: KeyboardEvent): void }

function useEscapePress(this: { cb?: TEmptyCallback }): ReturnType<() => TUseEscapePress> {
    const handleEscapePress: (event: KeyboardEvent) => void = useCallback((event: KeyboardEvent): void => {
        if (event.key === String("Escape")) this?.cb()
    }, [])

    return { handleEscapePress }
}

export { useAccessibleItemActions, type TUseAccessibleItemActions, useEscapePress, type TUseEscapePress }
