import { datadogLogs } from "@datadog/browser-logs"
import { datadogRum } from "@datadog/browser-rum"

import User from "@/models/user"
import { isParticipant } from "@/utils/gates/user"

export const setUserDataDog = (user: User) => {
    const userData = {
        id: `${user.id}`,
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        cohort: isParticipant(user) ? user.cohort : user.role,
        organization: user.organization,
        version: JSAPP_CONF?.static ?? ""
    }

    datadogRum.setUser(userData)

    datadogLogs.setUser(userData)
}
