import { type ReactElement } from "react"

import { Participant } from "@/models/participants"
import { Session } from "@/models/session"
import type { TEmptyCallback } from "@/shared/types/functions"

import type { TCoachTableHeaderColumn } from "./tables/CoachTableHeader"

enum ECoachTableHeaderName {
    Sessions_Cohort,
    Sessions_Time,
    Sessions_Type,
    Sessions_Participants,
    Sessions_Blank,
    Groups_Cohort,
    Groups_Name,
    Groups_SessionType,
    Groups_SessionTime,
    Groups_Participants,
    Participants_Name,
    Participants_Cohort,
    Participants_NextMeeting,
    Participants_CurrentModule
}

enum ECoachTableType {
    Sessions,
    Groups,
    Participants
}

type TDataStateForTable = {
    noSearchResults: boolean
    noSessionsData: boolean
    noParticipantsData: boolean
    noSessionsInCohort: boolean
}

type TRenderCoachTableColumnBodyFunction = (data: TCoachTableRowData) => ReactElement

type TCoachTableConfig = {
    tableType: ECoachTableType
    columnsBodies: TRenderCoachTableColumnBodyFunction[]
    columnHeaders: TCoachTableHeaderColumn[]
}

type TCoachTableRowData = Session | Participant

type THandleRowBodyClickFunction<T> = (data: T) => void

type TRenderCoachTableColumnBodyFunctionParams<T> = {
    data: T
    handleRowClick: THandleRowBodyClickFunction<T> | TEmptyCallback
    columnsBodies: TRenderCoachTableColumnBodyFunction[]
}

type TCoachTableBodyRowColumnsRenderMap = {
    [K in ECoachTableType]: (params: TRenderCoachTableColumnBodyFunctionParams<TCoachTableRowData>) => ReactElement
}

export {
    ECoachTableType,
    ECoachTableHeaderName,
    type TDataStateForTable,
    type TCoachTableConfig,
    type TRenderCoachTableColumnBodyFunction,
    type TCoachTableRowData,
    type TCoachTableBodyRowColumnsRenderMap,
    type TRenderCoachTableColumnBodyFunctionParams,
    type THandleRowBodyClickFunction
}
