import { type FC, type ReactElement } from "react"

type TProps = { color: "black" | "primary" }

const GuestParticipantActionArrow: FC<TProps> = ({ color }: TProps): ReactElement => (
    <svg width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0.785583 6.5H20.4284" stroke={color === "black" ? "#000000" : "#fd4d00"} strokeWidth="2" />
        <path
            d="M15.7144 1L21.2144 6.5L15.7144 12"
            stroke={color === "black" ? "#000000" : "#fd4d00"}
            strokeWidth="2"
        />
    </svg>
)

export { GuestParticipantActionArrow }
