import { type FC, type ReactElement, useCallback } from "react"

import { type NavigateFunction, useNavigate } from "react-router-dom"

import { Participant } from "@/models/participants"
import { Session } from "@/models/session"
import { CohortModalities } from "@/models/types"
import { emptyCallback } from "@/shared/types/functions"
import { isBonusSession } from "@/utils/gates/session"

import {
    ECoachTableType,
    type TCoachTableBodyRowColumnsRenderMap,
    type TCoachTableRowData,
    type THandleRowBodyClickFunction,
    type TRenderCoachTableColumnBodyFunction,
    type TRenderCoachTableColumnBodyFunctionParams
} from "../types"

import { CoachTableRow as TableRow } from "./CoachTableRow"

type TCoachTableBodyProps = {
    dataSource: Session[] | Participant[]
    type: ECoachTableType
    columnsBodies: TRenderCoachTableColumnBodyFunction[]
}

const tableBodyRowConfigRenderMap: TCoachTableBodyRowColumnsRenderMap = {
    [ECoachTableType.Sessions]: ({
        data,
        columnsBodies,
        handleRowClick
    }: TRenderCoachTableColumnBodyFunctionParams<Session>): ReactElement => (
        <TableRow
            key={`coach-table-row-${data.sessionId}`}
            rowData={data}
            handleRowClick={(): void => handleRowClick(data)}
            renderRowColumns={(data: Session): ReactElement[] =>
                columnsBodies.map((r: TRenderCoachTableColumnBodyFunction) => r(data))
            }
        />
    ),
    [ECoachTableType.Groups]: ({
        data,
        columnsBodies,
        handleRowClick
    }: TRenderCoachTableColumnBodyFunctionParams<Session>): ReactElement => (
        <TableRow
            key={`coach-table-row-${data.sessionId}`}
            rowData={data}
            handleRowClick={(): void => handleRowClick(data)}
            renderRowColumns={(data: Session): ReactElement[] =>
                columnsBodies.map((r: TRenderCoachTableColumnBodyFunction) => r(data))
            }
        />
    ),
    [ECoachTableType.Participants]: ({
        data,
        columnsBodies
    }: TRenderCoachTableColumnBodyFunctionParams<Participant>): ReactElement => (
        <TableRow
            key={`coach-table-row-${data.id}`}
            rowData={data}
            handleRowClick={emptyCallback}
            renderRowColumns={(data: Participant): ReactElement[] =>
                columnsBodies.map((r: TRenderCoachTableColumnBodyFunction) => r(data))
            }
        />
    )
}

const CoachTableBody: FC<TCoachTableBodyProps> = ({
    dataSource,
    columnsBodies,
    type = ECoachTableType.Sessions
}: TCoachTableBodyProps): ReactElement => {
    const navigate: NavigateFunction = useNavigate()

    const handleRowClick: THandleRowBodyClickFunction<Session> = useCallback(
        (session: Session): void => {
            if (session.cohortModality === CohortModalities.Individual || isBonusSession(session)) {
                navigate(`/session-participant/${session.participants[0].id}`)
                return
            }

            if (session.cohortModality === CohortModalities.Group) {
                navigate(`/group-info/${session.id}`)
            }
        },
        [navigate]
    )

    return (
        <tbody>
            {dataSource?.map(
                (data: TCoachTableRowData): ReactElement =>
                    tableBodyRowConfigRenderMap[type]({ data, columnsBodies, handleRowClick })
            )}
        </tbody>
    )
}

export { CoachTableBody, type TCoachTableBodyProps }
