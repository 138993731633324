import { type FC, type ReactElement } from "react"

type TGuestParticipantNoSessionsMessageProps = {
    message: string
}

const GuestParticipantMessage: FC<TGuestParticipantNoSessionsMessageProps> = ({
    message
}: TGuestParticipantNoSessionsMessageProps): ReactElement => <p className="text-center font-bold h3">{message}</p>

export { GuestParticipantMessage }
