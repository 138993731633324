import { DateTime } from "luxon"

import type { IAvailableGuestSessionModel } from "@/api/use-available-guest-sessions-query"
import { DATE_FORMAT } from "@/constants"
import { getHoursMinutesTime } from "@/utils/date"

import type { IAvailableSessionDataGroupedByWeek } from "./types"

const WEEK_RANGE_SEPARATOR = " - "

function getGroupedAvailableSessionData({
    sessionData,
    originalSessionTime
}: {
    sessionData: IAvailableGuestSessionModel[]
    originalSessionTime: string
}): IAvailableSessionDataGroupedByWeek[] {
    const groupedByWeekData = sessionData.reduce((acc, item: IAvailableGuestSessionModel) => {
        const date: DateTime = DateTime.fromISO(item.startTime)
        const weekStart: DateTime = date.startOf("week")
        const weekEnd: DateTime = weekStart.plus({ days: 6 })
        const dateRange: string = `${weekStart.toFormat(DATE_FORMAT.SHORT_DAY_SHORT_MONTH)}${WEEK_RANGE_SEPARATOR}${weekEnd.toFormat(DATE_FORMAT.SHORT_DAY_SHORT_MONTH)}`
        const dayKey: string = date.toFormat(DATE_FORMAT.SHORT_DAY_SHORT_MONTH_NUMBER)

        if (!acc[dateRange]) {
            acc[dateRange] = {
                weekRange: dateRange,
                sessions: {}
            }
            for (let day = weekStart; day <= weekEnd; day = day.plus({ days: 1 })) {
                acc[dateRange].sessions[day.toFormat(DATE_FORMAT.SHORT_DAY_SHORT_MONTH_NUMBER)] = []
            }
        }

        acc[dateRange].sessions[dayKey].push(item)

        return acc
    }, {})

    return Object.keys(groupedByWeekData).map(dateRange => {
        const weekStart: DateTime = DateTime.fromFormat(
            dateRange.split(WEEK_RANGE_SEPARATOR)[0],
            DATE_FORMAT.SHORT_DAY_SHORT_MONTH
        )
        const weekEnd: DateTime = weekStart.plus({ days: 6 })

        const preferredWeek: DateTime = DateTime.fromISO(originalSessionTime)

        return {
            weekRange: dateRange,
            isPreferredWeek:
                preferredWeek.toMillis() >= weekStart.toMillis() && preferredWeek.toMillis() <= weekEnd.toMillis(),
            sessions: Object.keys(groupedByWeekData[dateRange].sessions).map(day => ({
                weekDay: day,
                sessions: groupedByWeekData[dateRange].sessions[day]
            }))
        }
    })
}

function getFormattedGuestSessionDate(date: string, withDays: boolean = false): string {
    return `${withDays ? DateTime.fromISO(date).toFormat(DATE_FORMAT.SHORT_MONTH_DAY_NOT_PADDED) : ""}${withDays ? ", " : ""}${getHoursMinutesTime(DateTime.fromISO(date))}`
}

function shouldSwapSessionOpenerBeDisplayedBasedOnOriginalSessionTime(originalSessionTime: string): boolean {
    return DateTime.fromISO(originalSessionTime).toMillis() >= DateTime.now().plus({ seconds: 60 }).toMillis()
}

export {
    WEEK_RANGE_SEPARATOR,
    getGroupedAvailableSessionData,
    getFormattedGuestSessionDate,
    shouldSwapSessionOpenerBeDisplayedBasedOnOriginalSessionTime
}
