import { type Location, Navigate, matchPath, useLocation } from "react-router-dom"

import { isNullOrUndefined } from "$/utils/gates"
import { getIndexPage } from "$/utils/get-index-page"

import { StepUrls, dashboardURL, onboardingUrls } from "@/components/onboarding/constants"
import { AuthUrls, ConfigKeys, INDEX_URL, ParticipantUrls } from "@/constants"
import { type IStoreContext, useStoreContext } from "@/context"
import User from "@/models/user"
import {
    ELocalStorageServiceItemKey,
    ESessionStorageServiceItemKey,
    EStorageServiceType,
    StorageService
} from "@/services"
import PageLoader from "@/shared/spinner/PageLoader"
import useRoles, { type TUseRoles } from "@/utils/hooks/use-roles"

type Props = {
    isAllowed: boolean
    redirectPath?: string
    children?: JSX.Element
    isLoading?: boolean
    user: User
}

const localStorageService: StorageService = new StorageService(EStorageServiceType.Local)
const sessionStorageService: StorageService = new StorageService(EStorageServiceType.Session)

const ProtectedRoute = ({ children, isAllowed, redirectPath = AuthUrls.LOGIN, user, isLoading }: Props) => {
    const location: Location = useLocation()

    const { isParticipant }: TUseRoles = useRoles()
    const { appInit, getConfig }: IStoreContext = useStoreContext()
    const isIndex: boolean = location.pathname === INDEX_URL
    const isWaitingListPage =
        matchPath(ParticipantUrls.WAITING_LIST_SSO, location.pathname) ||
        matchPath(ParticipantUrls.WAITING_LIST, location.pathname)

    if (isLoading) {
        return <PageLoader />
    }

    if (
        !user &&
        !localStorageService.getItem<string>(ELocalStorageServiceItemKey.TokenAccess) &&
        !location.pathname.includes("register")
    ) {
        const state = sessionStorageService.getItem<string>(ESessionStorageServiceItemKey.UserLogout)
            ? null
            : { from: `${location.pathname}${location.search ?? String()}`, ...location }
        return <Navigate to={AuthUrls.LOGIN} state={state} replace />
    }

    if (!appInit) {
        return <PageLoader />
    }

    if (!user?.cohortExists && isParticipant && !isWaitingListPage) {
        const isSso = user?.unexpected
        const url = isSso ? `/waiting-list/unrecognized-sso/${user?.organizationSlug}` : ParticipantUrls.WAITING_LIST
        return <Navigate to={url} state={{ from: location }} replace />
    }

    if (
        user.eligibleSelectModule &&
        getConfig(ConfigKeys.hasEnrolledModules) &&
        location.pathname !== ParticipantUrls.ASSIGN_MODULE
    ) {
        return <Navigate to={ParticipantUrls.ASSIGN_MODULE} replace />
    }

    if (
        !isNullOrUndefined(matchPath(ParticipantUrls.SESSION_SWAPPING, location.pathname)) &&
        !user?.guestSessionsEnabled
    ) {
        return <Navigate to={ParticipantUrls.SESSION_LIST} replace />
    }

    if (isIndex) {
        if (user) {
            return (
                <Navigate
                    to={getIndexPage(user, getConfig(ConfigKeys.showDashboard))}
                    state={{ from: location }}
                    replace
                />
            )
        }
        return <Navigate to={AuthUrls.LOGIN} state={{ from: location }} replace />
    }

    if (isParticipant && !location.pathname.includes(ParticipantUrls.WAITING_LIST)) {
        if (!user?.onboardingFinished && !onboardingUrls.includes(location.pathname)) {
            if (!user.eligibleSelectModule) {
                return <Navigate to={StepUrls.ONBOARDING_REDIRECT_PAGE} state={{ from: location }} replace />
            }
        }

        if (user?.onboardingFinished && onboardingUrls.includes(location.pathname)) {
            return <Navigate to={dashboardURL} state={{ from: location }} replace />
        }
    }

    if (!isAllowed) {
        return <Navigate to={redirectPath} state={{ from: location }} replace />
    }

    return children
}

export default ProtectedRoute
