import { FC, useMemo } from "react"

import { type NavigateFunction, useNavigate, useParams } from "react-router-dom"

import usePhases from "@/api/use-phases"
import { type IAuthContext, useAuthContext } from "@/context"
import {
    type IUseTranslation,
    type TGetValueFromTranslatedObjectFunction,
    useGetValueFromTranslatedObject,
    useTranslation
} from "@/hooks"
import { RichTextRenderer } from "@/shared/rich-text-renderer"

import ChapterLayout from "./ChapterLayout"

interface IProps {}

const ChapterStart: FC<IProps> = () => {
    const params = useParams()
    const { t }: IUseTranslation = useTranslation()
    const navigate: NavigateFunction = useNavigate()

    const { user }: IAuthContext = useAuthContext()
    const { data } = usePhases(+params.moduleId)

    const getValueFromTranslatedObject: TGetValueFromTranslatedObjectFunction = useGetValueFromTranslatedObject()

    const renderFooter = () => (
        <div className="container-fluid">
            <div className="footer-welcome text-center">
                <span className="footer-welcome-icon">
                    <svg viewBox="0 0 26 24">
                        <path
                            d="M13 0.75L16.9369 8.03535L25.25 9.43973L19.37 15.3467L20.5709 23.5L13 19.8654L5.42908 23.5L6.63 15.3467L0.75 9.43973L9.06312 8.03535L13 0.75Z"
                            fill="currentColor"
                        />
                    </svg>
                </span>
                <h5 className="h5 text-uppercase color-inherit font-extrabold">{t("Optimize the Experience")}</h5>
                <p className="mb-0 font-sm">
                    {t(
                        "We have to go deep to make change. Be honest with yourself, recognize where you have room to grow, and be engaged in your practice."
                    )}
                </p>
            </div>
        </div>
    )

    const isFirstPhase = +params.phaseIndex === 0

    const currentModule = useMemo(() => {
        return user?.enrolledModules?.find(module => module.id === +params.moduleId)
    }, [user.enrolledModules, params.moduleId])

    const phaseName = data?.[+params.phaseIndex]?.name

    const pageTitle = `${getValueFromTranslatedObject(currentModule?.name)}: ${getValueFromTranslatedObject(phaseName)}`

    const onClickStart = () => {
        navigate(`/chapter/learn/${params.moduleId}/${params.phaseIndex}/${params.chapterIndex}/${params.id}`, {
            replace: true
        })
    }

    return (
        <ChapterLayout module={currentModule} title={pageTitle} renderFooter={renderFooter}>
            <div className="block-centered py-3 text-center ">
                <div className="h-100 d-flex align-items-center">
                    <div className="container--phase">
                        <h1 className="h1 font-extrabold welcome-title">
                            {isFirstPhase
                                ? `${t("Welcome")}: ${getValueFromTranslatedObject(currentModule?.name)}`
                                : pageTitle}
                        </h1>
                        <h2 className="h5 mb-3 text-uppercase color-brand font-extrabold">{t("What’s The Focus?")}</h2>

                        <div className="welcome-short-description">
                            <RichTextRenderer content={data?.[+params.phaseIndex]?.shortDescription} />
                        </div>

                        <div className="text-container">
                            <RichTextRenderer content={data?.[+params.phaseIndex]?.longDescription} />
                        </div>

                        <a href="#" className="btn btn-next btn--brand" onClick={onClickStart}>
                            {t("Start")}
                        </a>
                    </div>
                </div>
            </div>
        </ChapterLayout>
    )
}

export default ChapterStart
