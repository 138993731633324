import { useFormContext } from "react-hook-form"
import { Trans, useTranslation } from "react-i18next"

import Heading from "$/components/Heading/Heading"
import MarkdownRenderer from "$/components/MarkdownRenderer/MarkdownRenderer"
import getByFieldName from "$/utils/get-by-field-name"

import FreeResponseComponent from "@/components/onboarding/components/FreeResponseComponent"
import { ReflectionComponentType } from "@/constants"
import { SURVEY_STAGE, Survey as SurveyClass } from "@/entities/survey"
import DividerLine from "@/shared/divider/DividerLine"
import { LikertComponent } from "@/shared/likert/new-liker.tsx/NewLikertComponent"

type Props = {
    survey: SurveyClass
}

export const Survey = ({ survey }: Props) => {
    const { t } = useTranslation()
    const {
        register,
        control,
        formState: { errors }
    } = useFormContext()

    const isFinalSurvey = survey?.surveyStage === SURVEY_STAGE.FINAL

    return (
        <div>
            <Heading textAlign="center" className="mt-5">
                {survey?.surveyTemplate?.headerText ?? ""}
            </Heading>
            {survey?.surveyTemplate?.bodyText ? (
                <MarkdownRenderer content={survey.surveyTemplate.bodyText} />
            ) : (
                <p className="text-center">
                    <span className="font-extrabold">
                        {t(
                            "Please answer the following questions for {{participantFirstName}} {{participantLastName}}.",
                            {
                                participantFirstName: survey.participant.firstName,
                                participantLastName: survey.participant.lastName
                            }
                        )}
                    </span>{" "}
                    {t(
                        "Your responses will not be directly visible to {{participantFirstName}}, but will be visible to their coach. The coach will review all responses, and share insights back to {{participantFirstName}} as appropriate / relevant to their development.",
                        {
                            participantFirstName: survey.participant.firstName,
                            participantLastName: survey.participant.lastName
                        }
                    )}
                </p>
            )}

            {isFinalSurvey && (
                <p className="text-center">
                    <Trans
                        i18nKey="yourPreviousSurveyResponses"
                        components={{
                            1: <span className="color-accent font-extrabold" />
                        }}
                    />
                </p>
            )}
            <DividerLine fullWith className="my-5" />
            {survey.questions.map((question, index) =>
                question.isFreeResponse ? (
                    <FreeResponseComponent
                        key={question.id}
                        questionNumber={index + 1}
                        register={register}
                        item={{
                            question: question.questionData,
                            prompt: question.freeResponsePrompt
                        }}
                        name={`${question.id}.free_response`}
                        error={getByFieldName(errors, `${question.id}.free_response`)?.message}
                        errors={errors}
                        questionStyle="one-line"
                    />
                ) : (
                    <LikertComponent
                        item={question.answerOptions}
                        question={question.questionData}
                        key={question.id}
                        name={`${question.id}.answer`}
                        error={getByFieldName(errors, `${question.id}.answer`)?.message}
                        className={isFinalSurvey ? "mb-5" : "stakeholder-survey__likert mb-3"}
                        previousAnswer={question.previousAnswer}
                        questionNumber={index + 1}
                        color="blank"
                        register={register}
                        control={control}
                        errors={errors}
                        questionStyle="one-line"
                        likertType={ReflectionComponentType.LIKERT_GROUP}
                    />
                )
            )}
        </div>
    )
}
