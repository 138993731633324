import { type FC, type ReactElement } from "react"

import { Link } from "react-router-dom"

import { Participant } from "@/models/participants"
import Avatar from "@/shared/avatar/Avatar"

import { CoachTableGeneralCell } from "./CoachTableGeneralCell"

type TCoachTableAvatarCellProps = {
    participant: Participant
}

const CoachTableAvatarCell: FC<TCoachTableAvatarCellProps> = ({
    participant
}: TCoachTableAvatarCellProps): ReactElement => (
    <CoachTableGeneralCell>
        <Link className="d-flex align-items-center color-inherit" to={`/session-participant/${participant?.id}`}>
            <Avatar url={participant?.photo} className="mr-4" />
            <span>
                {participant?.firstName} {participant?.lastName}
            </span>
        </Link>
    </CoachTableGeneralCell>
)

export { CoachTableAvatarCell, type TCoachTableAvatarCellProps }
