import {
    type Dispatch,
    type FC,
    type ReactElement,
    type SetStateAction,
    startTransition,
    useCallback,
    useEffect,
    useState
} from "react"

import { QueryClient, useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { type NavigateFunction, useNavigate } from "react-router-dom"

import { fetchAvailableGuestSessions } from "@/api/use-available-guest-sessions-query"
import { ServerStateKeys } from "@/constants"
import { type IUseTranslation, useTranslation } from "@/hooks"
import { type TUseAccessibleItemActions, useAccessibleItemActions } from "@/hooks/use-accessible-item-actions"
import Button from "@/shared/button/Button"
import type { TEmptyAsyncCallback, TEmptyCallback } from "@/shared/types/functions"
import { accessibilityUtils } from "@/utils/accessibility"

import { shouldSwapSessionOpenerBeDisplayedBasedOnOriginalSessionTime } from "../utils"

type TGuestParticipantSwapSessionOpenerProps = { sessionTime: string; sessionId: number; shouldBeHidden: boolean }

const GuestParticipantSwapSessionOpener: FC<TGuestParticipantSwapSessionOpenerProps> = ({
    sessionTime,
    sessionId,
    shouldBeHidden
}: TGuestParticipantSwapSessionOpenerProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    const navigate: NavigateFunction = useNavigate()

    const queryClient: QueryClient = useQueryClient()

    const handleOpenerAction: TEmptyAsyncCallback = useCallback(
        async (): Promise<void> => (
            await queryClient.prefetchQuery([ServerStateKeys.AvailableGuestSessions, fetchAvailableGuestSessions]),
            navigate(`/session-swapping/${sessionId}`)
        ),
        [navigate, queryClient, sessionId]
    )

    const { handleKeyDown, handleClick }: TUseAccessibleItemActions = useAccessibleItemActions.call({
        cb: handleOpenerAction
    })

    const [shouldOpenerBeVisibleBasedOnSessionDate, setShouldOpenerBeVisibleBasedOnSessionDate]: [
        boolean,
        Dispatch<SetStateAction<boolean>>
    ] = useState<boolean>(shouldSwapSessionOpenerBeDisplayedBasedOnOriginalSessionTime(sessionTime))

    const handleOpenerVisibility: TEmptyCallback = (): void => {
        startTransition((): void =>
            setShouldOpenerBeVisibleBasedOnSessionDate(
                shouldSwapSessionOpenerBeDisplayedBasedOnOriginalSessionTime(sessionTime)
            )
        )
    }

    useEffect(
        () => (
            setInterval(handleOpenerVisibility, 1000), () => clearInterval(setInterval(handleOpenerVisibility, 1000))
        )
    )

    return (
        <Button
            {...accessibilityUtils.getTabIndexPropertyBasedOnBooleanCondition(
                !(shouldBeHidden || !shouldOpenerBeVisibleBasedOnSessionDate)
            )}
            variant="outline-secondary"
            onKeyDown={handleKeyDown}
            onClick={handleClick}
            className={classNames("swap-session__opener", {
                hidden: shouldBeHidden || !shouldOpenerBeVisibleBasedOnSessionDate
            })}
        >
            {t("participantSide.coachingSessionsScreen.swapSessionOpener")}
        </Button>
    )
}

export { GuestParticipantSwapSessionOpener, type TGuestParticipantSwapSessionOpenerProps }
