import { type FC, type LazyExoticComponent, type ReactElement, lazy } from "react"

import { type AxiosError } from "axios"
import classNames from "classnames"

import Heading from "$/components/Heading/Heading"
import { isNullOrUndefined } from "$/utils/gates"
import { getErrorMessages } from "$/utils/get-error-messages"

import type { IAvailableGuestSessionModel } from "@/api/use-available-guest-sessions-query"
import { type IUseTranslation, useTranslation } from "@/hooks"
import Coach from "@/models/coach"
import Button from "@/shared/button/Button"
import Modal, { type IModalProps } from "@/shared/modal"

import type { IOriginalSession } from "../types"

import { type TGuestParticipantSessionBlockProps } from "./GuestParticipantSessionBlock"

import { GuestParticipantActionArrow as ActionArrow } from "."

type TGuestParticipantConfirmSwapModalSubmitFunction = ({
    suggestedSessionId
}: {
    suggestedSessionId: number
}) => Promise<void>

type TGuestParticipantConfirmSwapModalProps = IModalProps & {
    sessionOriginal: IOriginalSession
    sessionSuggested: IAvailableGuestSessionModel
    coach?: Coach
    isSessionConfirmed: boolean
    mutationError?: AxiosError
    handleSubmit: TGuestParticipantConfirmSwapModalSubmitFunction
    isMutationSuccessful?: boolean
}

const SessionBlock: LazyExoticComponent<FC<TGuestParticipantSessionBlockProps>> = lazy(
    () => import("./GuestParticipantSessionBlock")
)

const GuestParticipantConfirmSwapModal: FC<TGuestParticipantConfirmSwapModalProps> = ({
    show,
    onClose,
    sessionOriginal,
    sessionSuggested,
    coach,
    isSessionConfirmed,
    mutationError,
    handleSubmit,
    isMutationSuccessful
}: TGuestParticipantConfirmSwapModalProps): ReactElement => {
    const { t }: IUseTranslation = useTranslation()

    return (
        <Modal
            disableBodyScroll
            show={show}
            onClose={onClose}
            modalBodyClass="swap-session__modal"
            headerContent={
                <Heading tag="h2" className="text-center">
                    {!isSessionConfirmed
                        ? t("participantSide.swapSessionsScreen.swapModal.title")
                        : t("participantSide.swapSessionsScreen.swapModal.confirmedTitle")}
                </Heading>
            }
        >
            <div
                className={classNames("swap-session__modal__container", {
                    "with-message": isSessionConfirmed || !isNullOrUndefined(mutationError)
                })}
            >
                <div
                    className={classNames("swap-session__modal__content", {
                        "pt-0": isSessionConfirmed || !isNullOrUndefined(mutationError)
                    })}
                >
                    <div
                        className={classNames("swap-session__modal__content__message", {
                            "d-none": !isSessionConfirmed && !!isNullOrUndefined(mutationError)
                        })}
                    >
                        {isSessionConfirmed && (
                            <div className="swap-session__modal__content__message__mark">
                                <i />
                            </div>
                        )}
                        <span className="font-extrabold">
                            {isSessionConfirmed
                                ? t("participantSide.swapSessionsScreen.swapModal.confirmedMessage")
                                : getErrorMessages(mutationError)}
                        </span>
                    </div>

                    <div className="swap-session__modal__content__sessions">
                        <SessionBlock
                            coachPhoto={sessionOriginal?.coach?.photo}
                            coachLastName={sessionOriginal?.coach?.last_name}
                            coachName={sessionOriginal?.coach?.first_name}
                            sessionTime={sessionOriginal?.session_time}
                        />

                        <div className="swap-session__modal__content__sessions__arrow-wrapper">
                            <ActionArrow color="black" />
                        </div>

                        <SessionBlock
                            coachPhoto={coach?.photo}
                            coachLastName={coach?.lastName}
                            coachName={coach?.firstName}
                            sessionTime={sessionSuggested?.startTime}
                        />
                    </div>

                    <Button
                        variant={isSessionConfirmed ? "outline-secondary" : "brand"}
                        onClick={() => handleSubmit({ suggestedSessionId: sessionSuggested.id })}
                        disabled={
                            !isNullOrUndefined(mutationError)
                                ? true
                                : isMutationSuccessful && isSessionConfirmed
                                  ? false
                                  : !isMutationSuccessful && isSessionConfirmed
                        }
                    >
                        <span className={classNames({ "font-extrabold": !isSessionConfirmed })}>
                            {!isSessionConfirmed
                                ? t("participantSide.swapSessionsScreen.swapModal.submit")
                                : t("participantSide.swapSessionsScreen.swapModal.confirmedSubmit")}
                        </span>
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export {
    GuestParticipantConfirmSwapModal as default,
    type TGuestParticipantConfirmSwapModalProps,
    type TGuestParticipantConfirmSwapModalSubmitFunction
}
