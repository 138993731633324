import { FC, useCallback, useEffect, useState } from "react"

import { yupResolver } from "@hookform/resolvers/yup"
import { AxiosResponse } from "axios"
import classNames from "classnames"
import { useForm } from "react-hook-form"
import { Trans } from "react-i18next"
import { Link, type NavigateFunction, useLocation, useNavigate, useParams } from "react-router-dom"

import Heading from "$/components/Heading/Heading"
import useIsMobileLandscape from "$/hooks/use-is-mobile-landscape"
import useMediaQuery from "$/hooks/use-media-query"
import { getErrorMessages } from "$/utils/get-error-messages"

import {
    type TUserCreatePasswordMutation,
    useUserCreatePasswordMutation
} from "@/api/mutations/use-user-create-password-mutation"
import "@/assets/auth.scss"
import { AuthUrls, CoachUrls, JS_CONF, MAX_WIDTH_MOBILE_MEDIA, ParticipantUrls, userRoles } from "@/constants"
import { type IAuthContext, useAuthContext } from "@/context"
import { type IUseTranslation, useTranslation } from "@/hooks"
import User, { UserApi } from "@/models/user"
import { ELocalStorageServiceItemKey, StorageService } from "@/services"
import { CopywriteText } from "@/shared/copywrite/CopywriteText"
import TextInput from "@/shared/input/TextInput"
import useNavigationOnboarding from "@/utils/hooks/use-navigation-onboarding"
import { registerSchema } from "@/validation"

import { WarningMessage } from "../onboarding/components/WarningMessage"

type FieldValues = {
    email: string
    password: string
    confirm_password: string
}

const storageService: StorageService = new StorageService()

const SetPassword: FC = () => {
    const { t }: IUseTranslation = useTranslation()
    const params = useParams()
    const navigate: NavigateFunction = useNavigate()
    const location = useLocation()
    const registrationKey = params.key
    const { getNextStepUrl } = useNavigationOnboarding()
    const { setUser, refetchUser }: IAuthContext = useAuthContext()
    const isMobile = useMediaQuery(MAX_WIDTH_MOBILE_MEDIA)
    const [errorsMsg, setErrorMsg] = useState(null)
    const resetPasswordPage = location.pathname.includes("reset-password")
    const isLandscape = useIsMobileLandscape()

    useEffect(() => {
        if (!JS_CONF.confirm_key || !JS_CONF.confirm_key_valid) {
            navigate(AuthUrls.LOGIN, {
                state: { showMessage: true }
            })
        }
    }, [navigate])

    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FieldValues>({
        resolver: yupResolver(registerSchema),
        defaultValues: {
            email: JS_CONF?.email || "",
            password: "",
            confirm_password: ""
        }
    })

    const onRegisterActions = useCallback(
        async (data: UserApi) => {
            storageService.removeItem(ELocalStorageServiceItemKey.MainUserLoggedOut)

            const currentUser: User = new User(data) // TODO: TD - refactor with user adapter
            setUser(currentUser)

            await refetchUser() // TODO TD - extend BE to return full user

            const isCoach = userRoles.coach.includes(currentUser.role)

            if (!currentUser?.cohortExists && !isCoach) {
                navigate(ParticipantUrls.WAITING_LIST)
                return
            }
            if (isCoach) {
                if (currentUser?.hasSubUsers) {
                    navigate(CoachUrls.WELCOME)
                } else {
                    navigate(CoachUrls.SESSIONS_LIST)
                }
            } else {
                await getNextStepUrl()
                    .then(step => navigate(step.url))
                    .catch(e => console.log(e))
            }
        },
        [getNextStepUrl, navigate, setUser, refetchUser]
    )

    const createPasswordMutation: TUserCreatePasswordMutation = useUserCreatePasswordMutation()

    const onSubmitRegister = useCallback(
        async values => {
            await createPasswordMutation.mutateAsync(
                {
                    registration_key: registrationKey,
                    password: values.password,
                    password_confirm: values.confirm_password,
                    tz: Intl.DateTimeFormat().resolvedOptions().timeZone
                },
                {
                    onSuccess: async data => {
                        await onRegisterActions((data as unknown as AxiosResponse<UserApi>)?.data)
                    },
                    onError: async error => {
                        setErrorMsg(getErrorMessages(error))
                        console.log(error)
                    }
                }
            )
        },
        [createPasswordMutation, navigate, onRegisterActions, registrationKey, resetPasswordPage]
    )

    return (
        <>
            <section
                className={classNames("registration-section welcome-section reset-section register vh-100-xs", {
                    "is-landscape": isLandscape
                })}
            >
                <Heading
                    tag="h3"
                    fontSize={resetPasswordPage ? 24 : 36}
                    className="registration-heading__bold"
                    textAlign={isMobile && resetPasswordPage ? "center" : "left"}
                >
                    {resetPasswordPage ? t("Password Reset") : t("Welcome to Wiser!")}
                </Heading>
                <p className="mb-30 fs-14 text-center text-md-left">
                    {t("In order to register with Wiser, please begin by creating a password.")}
                </p>
                <form className="registration-form mb-4" onSubmit={handleSubmit(onSubmitRegister)}>
                    <WarningMessage message={errorsMsg} align="start" />
                    {!resetPasswordPage && (
                        <TextInput
                            name="email"
                            register={register}
                            disabled
                            groupClassName="mb-2"
                            prefilled
                            errors={errors}
                            Icon={
                                <svg
                                    width="18"
                                    height="18"
                                    viewBox="0 0 18 18"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    aria-hidden="true"
                                >
                                    <path
                                        d="M12.397 7.45589V5.01076C12.397 3.29112 10.8765 1.89709 9 1.89709C7.12385 1.89709 5.60296 3.29248 5.60296 5.01076V7.45589H4.9138C4.27107 7.45589 3.75003 8.00994 3.75003 8.69022V14.8686C3.75003 15.5504 4.27072 16.103 4.9138 16.103H13.0862C13.729 16.103 14.25 15.5489 14.25 14.8686V8.69022C14.25 8.00849 13.7293 7.45589 13.0862 7.45589H12.397ZM12.75 8.99999V14.625H5.25V8.99999H12.75ZM7.125 7.45589V5.01076C7.125 3.99932 7.78382 3.37498 9 3.37498C10.217 3.37498 10.875 3.99838 10.875 5.01076V7.45589H7.125ZM9.61764 11.0286C9.61764 10.6875 9.34352 10.411 9 10.411C8.6589 10.411 8.38236 10.6851 8.38236 11.0286V12.8818C8.38236 13.2228 8.65648 13.4993 9 13.4993C9.3411 13.4993 9.61764 13.2252 9.61764 12.8818V11.0286Z"
                                        fill="#6F6F6F"
                                    />
                                </svg>
                            }
                        />
                    )}
                    <TextInput
                        name="password"
                        type="password"
                        className="register_input"
                        groupClassName="mb-2"
                        placeholder={resetPasswordPage ? t("New Password") : `${t("Create Password")}*`}
                        register={register}
                        isError={errors?.confirm_password?.type === "oneOf"}
                        errors={errors}
                    />
                    <TextInput
                        name="confirm_password"
                        type="password"
                        className="register_input"
                        groupClassName="mb-2"
                        placeholder={resetPasswordPage ? t("Confirm New Password") : `${t("Confirm Password")}*`}
                        register={register}
                        errors={errors}
                    />
                    <div className="form-group mt-input-reset mb-0 mt-input-signin mg-auto d-block text-center">
                        <button
                            className="registration-input__register registration-input__mobile registration-input__reset registration-input__register btn-flip"
                            data-back={resetPasswordPage ? t("Update Password") : t("Register")}
                            data-front={resetPasswordPage ? t("Update Password") : t("Register")}
                            aria-label={resetPasswordPage ? t("Update Password") : t("Register")}
                            disabled={!registrationKey}
                            type="submit"
                        />
                    </div>
                </form>
                <p className="registration-section__agreement color-gray ">
                    {/* TODO: WISER-3649 translation issue: on some browsers i18n is not being loaded */}
                    <Trans
                        defaults="By registering, you agree to our"
                        i18nKey="participantSide.registrationPage.registrationForm.privacyAgreementText"
                    />
                    &nbsp;
                    <Link
                        to="/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-underline d-block"
                    >
                        {/* TODO: WISER-3649 translation issue: on some browsers i18n is not being loaded */}
                        <Trans
                            defaults="privacy policy"
                            i18nKey="participantSide.registrationPage.registrationForm.privacyAgreementLink"
                        />
                    </Link>
                </p>
            </section>
            <CopywriteText
                withoutPrivacyPolicyLink
                className={classNames("text-center copywrite-text-bottom register", { "is-landscape": isLandscape })}
            />
        </>
    )
}
export default SetPassword
